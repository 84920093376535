
@mixin dark-theme-mode{
  --bg:  #0E1019;
  --bg-2:rgba(255,255,255,0.01);
  --bg-3:#0E1019;
  --bg-4:#0E1019;
  --bg-5:#0E1019;
  --bg-6:#0E1019;
  --bg-7:#13151C;
  --bg-8:#0E1019;
  --color-headings: #fff;
  --color-headings-opacity:rgba(255, 255, 255, 0.4);
  --color-texts-opacity: rgba(255, 255, 255,0.7);
  --color-texts: #fff;
  --btn-border: rgba(255,255,255,.3);
  --border-color:rgba(255,255,255,.08);
  --border-color-2:rgba(255,255,255,.08);
  --force-dark: #171a23;
}
@mixin light-theme-mode{
  --bg:  #fcfdfe;
  --bg-2:#f4f7fa;
  --bg-3:#f8f8f8;
  --bg-4:#fdfdff;
  --bg-5:#ecf2f7;
  --bg-6:#fff;
  --bg-7:#EDF9F2;
  --bg-8:#fbfbfb;
  --color-headings: #161c2d;
  --color-headings-opacity: rgba(22,28,45, 0.4);
  --color-texts-opacity: rgba(22,28,45, 0.7);
  --color-texts: #161c2d;
  --btn-border: rgba(3, 3, 3, 0.3);
  --border-color:#e7e9ed;
  --border-color-2:#eae9f2;

}

@mixin dark-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}
@mixin light-mode {
  [data-theme='dark'] & ,.dark-mode-texts & {
      @content;
  }
}

// #FCFDFE, #F4F7FA, #F8F8F8, #ECF2F7, #FDFDFF, 