.tel-content-image-group-1{
  min-height: 335px;
  width: 100%;
  position: relative;
  @include mobile-lg{
    margin-left: -9%;
  }
  @include desktops{
    margin-left: 0%;
  }
  .abs-image-1{
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    @include mobile{
      top: 13%;
    }
    @include mobile-lg{
      top: 5%;
      left: -2%;
    }
    @include tablet{
      top: 10%;
      left: 4%;
    }
    @include desktops{
      top: 10%;
      left: -25px;
    }
    @include large-desktops{
      left: 0;
    }
  }
  .abs-image-2{
    position: absolute;
    bottom: -15%;
    right: 0px;
    cursor: pointer;
    @include mobile{
      bottom: -8%;
      right: -6%;
    }
    @include mobile-lg{
      bottom: -13%;
      right: -17%;
    }
    @include tablet{
      bottom:-18%;
      right: -5%;
    }
    @include desktops{
      bottom: -5%;
      right: 0px;
      margin-bottom: -30px;
    }
    @include large-desktops{
      right: 20px;
      margin-bottom: -50px;
    }
  }
  .responsive-scaling-2{
    transform: scale(.7);
    @include mobile{
      transform: scale(.8);
    }
    @include mobile-lg{
      transform: scale(.9);
    }
    @include tablet{
      transform: scale(1);
    }
    @include desktops{
      transform: scale(.9);
    }
    @include large-desktops{
      transform: scale(1);
    }
  }
}


.tel-content-image-group-2{
  padding-top: 25px;
  display: inline-block;
  padding-left: 50px;
  padding-right: 25px;
  @include brk-point(400px){
    padding-left: 100px;
  }
  @include mobile-lg{
    padding-left: 125px;
  }
  >img{
    width: 100%;
    @include mobile{
      width: auto;
    }
  }
  .abs-image{
    &-1{
     position: absolute;
     bottom:40px;
     left: 0;
     z-index: 1;
     zoom: 70%;
     @include brk-point(370px){
      zoom: 80%;
     }
     @include brk-point(400px){
      zoom: 100%;
     }
    }
    &-2{
      position: absolute;
      height: 100%;
      z-index: -1;
      top: 0px;
      right:0px;
      >img{
        height: 85%;
        @include brk-point(420px){
          height: auto;
        }
      }
    }
  }
}


.hover-shadow-up{
  &:hover{
    .anim-shadow-up{
      box-shadow: 0 32px 44px -15px rgba(1, 16, 30, 0.18);
    }
  }
}
.anim-shadow-up{
  box-shadow: 0 32px 44px -15px rgba(1, 16, 30, 0.18);
  @include desktops{
    box-shadow: 0 32px 44px -15px rgba(1, 16, 30, 0);
  }
  transition: .4s;
}