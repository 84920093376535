.card-list{
  max-width: 315px;
  margin: 30px 0;
  li{
    color: $blackish-blue;
    // font-size: 17px;
    // font-weight: 400;
    letter-spacing: -0.2px;
    line-height: 29px;
    margin-bottom: 13px;
    display: flex;
    &.disabled {
      color: rgba($blackish-blue, 0.7);
      // opacity: .7;
      // color: #7D818D;
      // &:before{
      //   content: "\f00d";
      //   color: #D5D7DD;
      // }
      i {
        color: #d5d7dd;
      }
    }
  }
}

.card-columns {
  column-count: 1;
  @include mobile-lg {
    column-count: 2;
  }
  &.column-3 {
    @include tablet {
      column-count: 3;
    }
  }
}


.job-card-hover{
  
  i{
    transition: .4s;
  }
  &:hover{
    i{
      color: $primary!important;
    }
  }
  
}


.category-card {
  transition: .4s;
  background-color: #fff;
  .title {
    color: $blackish-blue;
  }
  .sub-title {
    color: rgba($blackish-blue, 0.7);
  }

  .card-icon {
    background-color: $blue;
    @include tablet {
      transition: .3s;
      transform: scale(0);
      // transform-origin: center;
      background-color: rgba(#fff, 0.2);

    }
  }
  &:hover {
    @include tablet{
      background-color: $blue;
      .card-title {
        color: #fff;
      }
      .sub-title {
        color: rgba(#fff, 0.7);
      }
      .card-icon {
        transform: scale(1);
      }
    }
  }
}