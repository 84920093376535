.bg-gradient {
  position: relative;
  z-index: 1;
  &-black {
    &:before {
      background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, $blackish-blue 100%);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  &-white {
    &:before {
      background-image: linear-gradient(270deg, rgba(0, 0, 0, 0) 0%, #fbfbfb 100%);
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: -1;
    }
  }
  &-1 {
    position: relative;
    background: linear-gradient(225deg, #313c59 0%, $blackish-blue 100%);
  }
  &-2{
    background-image: linear-gradient(to right, #F8F8F8 0%, #EBEBEB 100%);
  }
  &-3{
    background-image: linear-gradient(to bottom, var(--bg-2) 62%, var(--bg-4) 62%, var(--bg-4) 100%);
  }
}


.bg-overlay {
  position: relative;
  z-index: 1;
  &:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
  }
  &.overlay-1 {
    &:before {
      background: $blackish-blue;
      opacity: 0.51;
    }
  }
  &.overlay-2 {
    &:before {
      background: $blackish-blue;
      opacity: 0.8;
    }
  }
}